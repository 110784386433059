<template>
  <div class="elearning">
    <div class="d-flex flex-column flex-sm-row flex-md-row flex-lg-row flex-xl-row align-md-center justify-space-between">
      <span class="text-xl-h4 text-lg-h4 text-md-h4 text-sm-h5 text-h6 font-weight-bold black--text">
        Siswa/i
      </span>
      <div class="d-md-flex d-flex mt-4 mt-sm-0 mt-md-0 mt-lg-0 mt-xl-0">
        <div class="mt-2">
          <h4>
            Filters:
          </h4>
        </div>
        <div class="d-flex align-center">
          <v-autocomplete
            class="ml-4 filter-auto-complete"
            label="By Name"
            dense
            outlined
          ></v-autocomplete>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="elearning-subject mt-8">
      <v-row>
        <v-col
          v-for="child in childs"
          :key="child"
          cols="12"
          sm="6"
          md="4"
          lg="4"
          xl="4"
        >
          <v-card>
            <v-card-text class="pa-4 text-md-center">
              <v-row class="align-center">
                <v-col
                  cols="2"
                  md="12"
                  class="py-0"
                >
                  <div>
                    <v-avatar
                      width="80"
                      height="80"
                      class="mt-6"
                    >
                      <v-img src="../../../assets/images/avatars/ari.png">
                      </v-img>
                    </v-avatar>
                  </div>
                </v-col>
                <v-col
                  offset="2"
                  offset-md="0"
                  md="12"
                  class="py-0"
                >
                  <h3 class="text-h6 black--text mb-6 mt-4">
                    {{ child.name }}
                  </h3>
                </v-col>
              </v-row>
              <v-row class="px-4">
                <v-divider></v-divider>
              </v-row>
              <v-row>
                <v-col md="6">
                  <div class="d-flex d-md-flex justify-center">
                    <div class="mr-2">
                      <v-img
                        width="20"
                        :src="child.class.icon"
                      ></v-img>
                    </div>
                    <span> Kelas {{ child.class.title }}</span>
                  </div>
                </v-col>
                <v-col>
                  <div class="d-flex d-md-flex justify-center">
                    <div class="mr-2">
                      <v-img
                        width="20"
                        :src="child.elearning.icon"
                      ></v-img>
                    </div>

                    <span> {{ child.elearning.title }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="px-0 py-0">
              <router-link
                class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--x-large primary"
                to="/elearning-parent/subject"
                style="width: 100%;"
              >
                Lihat E-Learning
              </router-link>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import imgUser from '../../../assets/images/avatars/ari.png'
import iconClass from '../../../assets/icons/class.svg'
import iconElearning from '../../../assets/icons/devices.svg'

export default {
  data() {
    return {
      childs: [
        {
          thumbnail: imgUser,
          name: 'Ridho Mckinnon',
          class: {
            icon: iconClass,
            title: 'VI',
          },
          elearning: {
            icon: iconElearning,
            title: '3 Elearning',
          },
        },
        {
          thumbnail: imgUser,
          name: 'Ridho Mckinnon',
          class: {
            icon: iconClass,
            title: 'VI',
          },
          elearning: {
            icon: iconElearning,
            title: '3 Elearning',
          },
        },
      ],
    }
  },
}
</script>

<style>
@media only screen and (max-width: 600px) {
  .filter-auto-complete {
    width: 150px;
  }
}
</style>
